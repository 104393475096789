<template>
  <!-- My 질문 -->
  <coach-my-page-content-layout coach-user-questions>
    <my-page-title>
      <template #left>
        MY 질문
      </template>
      <template #right>
        <client-only>
          <color-button class="btn-post-question" type="orange" @click="postQuestion" v-if="!isCoach">질문하기</color-button>
        </client-only>
      </template>
    </my-page-title>
    <div class="questions-tab">
      <router-link :to="{query: {'question-type': 'my-question'}}" :class="{active:questionType === 'my-question'}">MY 질문</router-link>
      <router-link :to="{query: {'question-type': 'scrap'}}" :class="{active:questionType === 'scrap'}">MY 스크랩</router-link>
    </div>
    <div class="content-area">
      <div class="content-area-inner">
        <div class="filters">
          <button-filter :class="{active: !hasLane && !hasChamp}" @click="resetFilter">전체</button-filter>
          <button-filter v-show="!hasLane" @click="setLane">
            <span>포지션</span>
          </button-filter>
          <button-filter v-show="hasLane" class="active" @click="setLane">
            <span>포지션 :</span>
            <em>{{ lolLaneText }}</em>
          </button-filter>
          <button-filter v-show="!hasChamp" @click="setChamp">
            <span>챔피언</span>
          </button-filter>
          <button-filter v-show="hasChamp" class="active" @click="setChamp">
            <span>챔피언 :</span>
            <lol-champ-data tag="em" :champ-code="lolChamp" v-slot="{ champText }">{{ champText }}</lol-champ-data>
          </button-filter>
        </div>
        <template v-if="NoQuestion">
          <no-content v-if="questionType === 'waiting'" :text="$t('_.MC_NO_DATA_LIST_NOW_WAITING')" alt="memopad" src="/img/coaching/memopad.svg" />
          <no-content v-if="questionType === 'answered'" :text="$t('_.MC_NO_DATA_LIST_NOW_DONE')" alt="memopad" src="/img/coaching/memopad.svg" />
          <no-content v-if="questionType === 'my-question'" :text="$t('_.MC_NO_DATA_MYMENU_QUESTION')" alt="memopad" src="/img/coaching/memopad.svg" />
          <no-content v-if="questionType === 'scrap'" :text="$t('_.MC_NO_DATA_LIST_SCRAP')" alt="eyes" src="/img/coaching/seeking-eyes.svg" />
        </template>
        <question-preview v-for="q in questions" :question="q" :key="q.question.boardWriteId" clickable @examine="openQuestion" />
        <area-more v-if="hasMore" @click="getMore" />
      </div>
    </div>
    <client-only>
      <color-button class="btn-post-question-mobile" type="orange" @click="postQuestion" v-if="!isCoach">질문하기</color-button>
    </client-only>
  </coach-my-page-content-layout>
</template>

<script>
import cursorFetcher from '@shared/mixins/cursorFetcher';
import { getter } from '@shared/utils/storeUtils';
import ColorButton from '@shared/components/common/ColorButton.vue';
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout.vue';
import MyPageTitle from '@/views/components/coaching/coach-my-page/MyPageTitle.vue';
import ButtonFilter from '@/views/components/coaching/question/ButtonFilter.vue';
import QuestionPreview from '@/views/components/coaching/question/QuestionPreview.vue';
import AreaMore from '@/views/components/coaching/question/AreaMore.vue';
import questionsFilter from '@/mixins/questionsFilter';
import NoContent from '@/views/components/coaching/question-detail/NoContent.vue';
import coach from '@/mixins/coach';
import { LOL_POSITION_TEXT } from '@/constants/lol';
import LolChampData from '@/views/components/coaching/LolChampData.vue';

export default {
  name: 'CoachUserQuestions',
  lexicon: 'coaching',
  components: {
    MyPageTitle, CoachMyPageContentLayout, LolChampData, NoContent, AreaMore, QuestionPreview, ButtonFilter, ColorButton,
  },
  data: () => ({
    gameInfo: null,
    questionInfo: /** @type{PagedQuestions} */ null,
  }),
  metaInfo() {
    return {
      title: this.$t('meta.myQuestion.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.myQuestion.title') },
        { vmid: 'url', content: this.$t('meta.myQuestion.url') },
        { vmid: 'description', content: this.$t('meta.description') },
        { vmid: 'keywords', content: this.$t('meta.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  mixins: [coach, questionsFilter, cursorFetcher({
    questionInfo: ({ cursor, route, services, questionType, lolLane, lolChamp }) => {
      const { gameId } = route.params;
      const opt = { cursor };
      const category = [];
      if (lolLane && lolLane !== '') {
        category.push({ groupName: 'LOL_LANE', values: [lolLane] });
      }
      if (lolChamp && lolChamp !== '') {
        category.push({ groupName: 'LOL_CHAMPION', values: [lolChamp] });
      }
      if (category.length >= 1) opt.category = category;
      switch (questionType) {
        case 'my-question':
          opt.q = 'isMyQuestion eq true';
          break;
        case 'scrap':
          opt.q = 'isScrap eq true';
          break;
        default:
          opt.q = 'isMyQuestion eq true';
      }
      opt.orderBy = 'latestAnswerDatetime';
      opt.order = 'desc';
      return services.coaching.getQuestions({ size: 5, gameId, ...opt });
    },
  })],
  computed: {
    isLogin: getter('auth', 'isLogin'),
    isCoach: getter('auth', 'isCoach'),
    questionType: {
      get() {
        return this?.$route?.query?.['question-type'] || 'my-question';
      },
      set(questionType) {
        const query = this.getMergedQuery({ 'question-type': questionType });
        this.$router.push({ query });
      },
    },
    questions() {
      return /** @type{PreviewQuestion[]} */ this.questionInfo?.items || [];
    },
    NoQuestion() {
      return this.questionInfo?.totalCount === 0;
    },
    hasMore() {
      return this.questionInfo?.hasNext;
    },
    lolLaneText() {
      return this.$t(`coaching.${LOL_POSITION_TEXT[this.lolLane]}`);
    },
  },
  methods: {
    getMore() {
      return this.fetchMore(this.questionInfo);
    },
  },
  async asyncData({ route, store, services, mixinFetcher }) {
    const gameInfo = { id: 'lol', ipList: store.state.info.games };
    const lolLane = route.query?.['lol-lane'] || '';
    const lolChamp = route.query?.['lol-champ'] || '';
    const questionType = route.query?.['question-type'] || 'answered';
    return { gameInfo, ...await mixinFetcher.fetch({ route, services, defaultParams: { lolLane, lolChamp, questionType } }) };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-user-questions] {
  [question-preview] {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    & + [question-preview] {.mt(12)}
  }
  [area-more] {.mt(18)}
  .btn-post-question { .wh(260, 60);.fs(20);border-radius: 50px;.block;}
  .filters {.mb(20);
    [button-filter] {.ml(8);}
  }
  .btn-post-question-mobile {.hide}
  .questions-tab {
    .mt(50);.mb(50);.pb(15);
    a {
      .fs(16);.bold;.pb(15);.pl(10);.pr(10);
      border-bottom: solid 2px transparent;
      &.active {
        color: #8300ff;
        border-color: #8300ff;
      }
    }
  }
  @media (@tp-down) {
    .content-area {.m(15)}
    .btn-post-question-mobile {display: block;position: fixed;.w(100%);border-radius: 0;.b(0);.z(10)}
    .btn-post-question { .hide}
    .questions-tab {.ml(10);.mr(10);}
  }
}
</style>
